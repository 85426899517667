import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartnerList } from "../reduxToolKit/slices/partnerListApiSlice";

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TrustedPartner = () => {
    const partners = useSelector((state) => state?.partnerList.partnerList.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPartnerList())
    }, [])


    const options = {
        rewind: true,
        autoplay: true,
        autoplayTimeout: 5000, // Time between slides
        smartSpeed: 600, // Speed of transition
        dots: false,
        loop: true,
        margin: 10,
        responsive: {
            0: { items: 3 },
            600: { items: 5 },
            1000: { items: 6 }
        }
    };

    const events = {
        // onDragged: function (event) {...},
        // onChanged: function(event) { ...}
    };
    return (
        <div>
            <div
                className="tab-content"
            >
                <div
                //className="tab-pane show active" 
                >
                    <OwlCarousel className="owl-theme" options={options} events={events}>
                        {partners?.length > 0 ? partners.map((item) => {
                            return (
                                <figure>
                                    <img src={item?.image} height="70px" width="100px" style={{ margin: ' 0 10px' }} />
                                </figure>
                            )
                        }) : ""}
                    </OwlCarousel>
                </div>
            </div></div>
    )
}

export default TrustedPartner